import { ReactNode, useCallback, useEffect } from "react";
import { useSigningClient } from "contexts/cosmwasm";
import Loader from "./Loader";
import { Button, Text, VStack } from "@chakra-ui/react";

function WalletLoader({
  children,
  loading = false,
}: {
  children: ReactNode;
  loading?: boolean;
}) {
  const {
    walletAddress,
    loading: clientLoading,
    error,
    connectWallet,
    disconnect,
  } = useSigningClient();

  const reconnect = useCallback(() => {
    disconnect();
    connectWallet();
  }, [disconnect, connectWallet]);

  useEffect(() => {
    window.addEventListener("keplr_keystorechange", reconnect);

    return () => {
      window.removeEventListener("keplr_keystorechange", reconnect);
    };
  }, [reconnect]);

  if (loading || clientLoading) {
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    );
  }

  if (walletAddress === "") {
    return (
      <VStack mt="4em">
        <Text fontSize="2xl" as="b">
          Connect your wallet to see airdrop eligibility
        </Text>
        <Button colorScheme="blue" onClick={() => connectWallet()}>
          Connect Wallet
        </Button>
      </VStack>
    );
  }

  if (error) {
    return <code>{JSON.stringify(error)}</code>;
  }

  return <>{children}</>;
}

export default WalletLoader;
