import type { NextPage } from "next";
import Head from "next/head";
import WalletLoader from "components/WalletLoader";
import { useSigningClient } from "contexts/cosmwasm";
import { useEffect, useState } from "react";
import receivers from "../public/airdrop.json";
import { AirdropFileEntry, generateProof } from "../util/airdrop";
import { config } from "util/config";
import {
  Flex,
  Button,
  HStack,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { convertMicroDenomToDenom } from "util/conversion";
import PillPity from "pill-pity";

const Home: NextPage = () => {
  const toast = useToast();
  const { walletAddress, signingClient, disconnect } = useSigningClient();
  const [userAirdropDetails, setUserAirdropDetails] =
    useState<AirdropFileEntry | null>(null);
  const [hasClaimed, setHasClaimed] = useState(false);

  useEffect(() => {
    const main = async () => {
      if (walletAddress && signingClient) {
        const casted = receivers as {
          address: string;
          amount: string;
        }[];
        const filt = casted.filter((drop) => drop.address === walletAddress);
        if (filt.length === 0) {
          // Not eligible
          setUserAirdropDetails(null);
        } else {
          // Eligible
          const drop = filt[0];
          setUserAirdropDetails(drop);
          console.log("dropped: ", drop);
          const resp: { is_claimed: boolean } =
            await signingClient.queryContractSmart(config("airdropAddress"), {
              is_claimed: {
                stage: parseInt(config("stage")),
                address: drop.address,
              },
            });
          setHasClaimed(resp.is_claimed);
        }
      }
    };
    main();
  }, [walletAddress, signingClient]);

  const claimAirdrop = async () => {
    if (!userAirdropDetails || !walletAddress || !signingClient) {
      return;
    }
    console.log("gen proof");
    const proof = generateProof(walletAddress, userAirdropDetails.amount);
    console.log("proof", proof);
    const msg = {
      claim: {
        stage: parseInt(config("stage")),
        amount: userAirdropDetails.amount,
        proof,
      },
    };
    console.log("msg", msg);
    try {
      await signingClient.execute(
        walletAddress,
        config("airdropAddress"),
        msg,
        "auto"
      );
      console.log("successfully claimed!");
      toast({
        title: "Airdrop successfully claimed!",
        status: "success",
      });
      setHasClaimed(true);
    } catch (err) {
      // todo add pop up
      console.log("failed with", err);
      toast({
        title:
          "An error has occurred, check you have JUNO tokens in your wallet, and that the claim window has not ended.",
        status: "error",
      });
    }
  };

  return (
    <PillPity
      minH="1000px"
      pb="30em"
      pl="4em"
      pr="4em"
      pt="4em"
      pattern="topography"
      as={Flex}
      justify="center"
      align="center"
      patternFill="#32a6a3"
      bgColor="#f2b705"
      patternOpacity={1.0}
    >
      <Head>
        <title>Howl Social Airdrop</title>
      </Head>
      <VStack justify="center">
        <HStack mt="4em"></HStack>
        <HStack>
          <Image src={"/mascot-m.png"} height="1000px" alt="Logo" mx="0.4em" />

          <VStack
            rounded="xl"
            bg="rgba(111,111,111,0.2)"
            pl="4em"
            pr="4em"
            pb="4em"
          >
            <WalletLoader>
              {!userAirdropDetails && (
                <VStack mt="4em">
                  <Text fontSize="2xl" as="b">
                    {walletAddress}
                  </Text>
                  <Text fontSize="2xl">
                    {" "}
                    was not eligible for the Howl airdrop
                  </Text>
                </VStack>
              )}
              {userAirdropDetails && (
                <VStack>
                  {hasClaimed && (
                    <VStack mt="4em">
                      <Text fontSize="2xl" as="b">
                        {walletAddress}
                      </Text>
                      <Text fontSize="2xl">
                        {" "}
                        has claimed{" "}
                        {convertMicroDenomToDenom(
                          userAirdropDetails.amount
                        )}{" "}
                        HOWL
                      </Text>
                    </VStack>
                  )}
                  {!hasClaimed && (
                    <VStack mt="4em">
                      <Text fontSize="2xl" as="b">
                        {walletAddress}
                      </Text>
                      <Text fontSize="2xl">
                        {" "}
                        can claim{" "}
                        {convertMicroDenomToDenom(
                          userAirdropDetails.amount
                        )}{" "}
                        HOWL
                      </Text>
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          claimAirdrop();
                        }}
                      >
                        Claim
                      </Button>
                    </VStack>
                  )}
                </VStack>
              )}
              <HStack mt="4em">
                <Button colorScheme="red" onClick={() => disconnect()}>
                  Disconnect Wallet
                </Button>
              </HStack>
            </WalletLoader>
          </VStack>
        </HStack>
      </VStack>
    </PillPity>
  );
};

export default Home;
